
  .loadermodal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}
  
.loadermodal .loading-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(0,147,148,46) 0.1%, rgba(0,172,175,46) 94.2% );
    background-size: 100%;
    font-family: 'Montserrat', sans-serif;
    overflow: hidden;
  }

.loadermodal  .loading-container {
    background-image: radial-gradient( circle farthest-corner at 10% 20%, #3c78bce8 0.1%, #3c78bcf9 94.2% );
    width: 80%;
    height: 80%;
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    overflow-y: auto;
    scrollbar-width: none;
    background-color: #fbfbfb;
    border-radius: 10px;
}
  
.loadermodal .loading-container {
    width: 100%;
    max-width: 520px;
    height: 100%;
    max-height: 320px; 
    text-align: center;
    color: #fff;
    position: relative;
    margin: 0 32px;
  }
.loadermodal  .loading-container:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #fff;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    -webkit-animation: movingLine 2.4s infinite ease-in-out;
            animation: movingLine 2.4s infinite ease-in-out;
  }
  
  @-webkit-keyframes movingLine {
    0% {
      opacity: 0;
      width: 0;
    }
    33.3%, 66% {
      opacity: 0.8;
      width: 100%;
    }
    85% {
      width: 0;
      left: initial;
      right: 0;
      opacity: 1;
    }
    100% {
      opacity: 0;
      width: 0;
    }
  }
  
  @keyframes movingLine {
    0% {
      opacity: 0;
      width: 0;
    }
    33.3%, 66% {
      opacity: 0.8;
      width: 100%;
    }
    85% {
      width: 0;
      left: initial;
      right: 0;
      opacity: 1;
    }
    100% {
      opacity: 0;
      width: 0;
    }
  }
.loadermodal  .loading-text {
    font-size: 5vw;
    line-height: 64px;
    letter-spacing: 10px;
    display: flex;
    justify-content: space-evenly;
  }
.loadermodal .loading-text span {
    -webkit-animation: moveLetters 2.4s infinite ease-in-out;
            animation: moveLetters 2.4s infinite ease-in-out;
    transform: translatex(0);
    position: relative;
    display: inline-block;
    opacity: 0;
    text-shadow: 0px 2px 10px rgba(46, 74, 81, 0.3);
  }
  
.loadermodal .loading-text span:nth-child(1) {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
  }
  
.loadermodal .loading-text span:nth-child(2) {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  
.loadermodal .loading-text span:nth-child(3) {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
  }
  
.loadermodal .loading-text span:nth-child(4) {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }
  
.loadermodal .loading-text span:nth-child(5) {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  
.loadermodal .loading-text span:nth-child(6) {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }
  
.loadermodal .loading-text span:nth-child(7) {
    -webkit-animation-delay: 0.7s;
            animation-delay: 0.7s;
  }
  
  @-webkit-keyframes moveLetters {
    0% {
      transform: translateX(-15vw);
      opacity: 0;
    }
    33.3%, 66% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(15vw);
      opacity: 0;
    }
  }
  
  @keyframes moveLetters {
    0% {
      transform: translateX(-15vw);
      opacity: 0;
    }
    33.3%, 66% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(15vw);
      opacity: 0;
    }
  }
  
  