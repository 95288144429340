.services{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 20px;
    padding-top: 20px;
    background: url('./img/pics/ANGOLA.png') no-repeat center center;
    background-color: #eaeaea;
}

.services h1{
    position: relative;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 28px;
    color: #3c78bc;
}

.services h1:before,
.services h1:after {
    content: "";
    display: block;
    height: 2px;
    width: 60px;
    position: absolute;
    top: 50%;
    left: 130%;
    background-color: #3c78bc;
    transform: translate(-50%, -50%);
}

.services h1:before {
    left: -30%;
}

.services .services__service{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    padding: 0 60px;
}