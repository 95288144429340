@media (max-width: 500px) {
    .app .companyinfo .company-info{
        flex-direction: column;
    }

    .app .company-info .text-container {
        flex-grow: 1;
        padding: 20px 0px;
    }

    .app .companyinfo {
        padding: 50px 5%;
        padding-top: 0;
    }

    .app .contact-component .image-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 5px;
        background-color: #eaeaea;
    }

    .app .contact-component .info-container {
        display: flex;
        flex-direction: column;
        padding: 30px 10px;
        padding-bottom: 40px;
        background-color: #eaeaea;
        gap: 20px;
    }

    .app .contact-component .info-item:last-child {
        border-radius: 50px;
    }

    .app .contact-component .info-item {
        display: flex;
        border: 1px solid rgb(174, 174, 174);
        align-items: center;
        padding: 15px 25px;
        border-radius: 50px;
        box-shadow: 0px 0px 5px 0px rgb(174, 174, 174);
        width: calc(100% - 50px);
    }

    .app .footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 0px;
        padding: 0;
        border: 1px solid #eaeaea;
    }

    .app .footer-left {
        padding-top: 30px;
        padding-bottom: 20px;
    }

    .app .footer .footer-center img {
        width: 100%;
    }
    
    .app .contact-component .title-container label {
        font-size: 13px;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        color: rgb(51, 51, 51);
        display: flex;
        flex-wrap: wrap;
    }

    .app .contact-component {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        padding: 0 0px;
        width: 100%;
    }

    .app .contact-component .icon img {
        width: 20px;
    }

    .app .banne .banner {
        width: 100%;
        height: 200px;
        position: relative;
    }

    .app .services {
        margin-top: 200px;
    }

    .app .banne .banner__box {
        top: 100%;
    }

    .app .services .services__service {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
        padding: 0 20px;
    }

    .app .banne .banner__box .banner__boxer .boxeach {
        height: 100px;
        width: 118px;
    }

    .app .banne .banner__box .banner__boxer .boxeachactive {
        height: 118.5px;
        width: 158px;
    }

    .app .banne .banner__box .banner__box__tittle h1 {
        margin-bottom: 5px;
    }

    .app .modal .modal__closemodal button {
        margin-right: 5.4%;
        margin-bottom: -5.2%;
    }

    .app .modal .modal__contanair {
        padding: 1% 8%;
    }

    .app .loadermodal .loading-container {
        max-width: 280px;
        max-height: 150px;
        margin: 0px;
    }

    .app header {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 20%;
        padding: 5px 20px;
        position: relative;
    }

}