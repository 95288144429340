.footer {
    display: flex;
    justify-content: space-between;
    margin: 0 0px;
    padding: 0 20px;
    border: 1px solid #eaeaea;
}

.footer-left, .footer-center, .footer-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-left {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
}

.social{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #eaeaea;
    cursor: pointer;
}

.footer .social span{
    color: #3c78bc;
    font-weight: bold;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.social:hover{
    background-color: #eef2f7;
}

.social img{
    width: 30px;
}

.footer-center {
    background-color: #eaeaea;
    padding: 15px;
    padding-top: 50px;
    padding-bottom: 70px;
    height: 100%;
}

.footer-center img {
    width: 450px;
}

.footer-right {
    display: flex;
    flex-direction: column;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: #3c78bc;
    cursor: pointer;
    font-weight: bold;
}

.sociall{
    border-bottom: 1px solid #eaeaea;
    cursor: pointer;
}

.footer-right p{
    position: relative;
    overflow: hidden;
}

.footer-right p:hover {
    color: #76b4fa;
}

.footer-right p::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -100%;
    width: 200%;
    height: 2px;
    background: linear-gradient(to right, #76b4fa 50%, transparent 50%);
    transition: left .5s;
}

.footer-right p:hover::after {
    left: -50%;
}