.service-box {
    width: 300px;
    height: 440px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ffffff;
    background-color: #ffffff;
    border-radius: 5px;
}

.service-box .service-img {
    width: 100%;
    height: auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.service-box h2{
    margin: 0;
}

.service-box .service-title {
    margin: 16px 10px;
    margin-bottom: 6px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 16px;  
}

.service-box .service-description {
    font-size: 14px;
    margin: 2px 10px;
    /* text-align: justify; */
    margin-top: 4px;
    color: #3c78bc;
}

.service-box div{
    margin: 10px 10px;
    margin-top: -2px;
    border-top: 2px solid #000;
    width: 40px;
}