.companyinfo{
    padding: 50px 15%;
    padding-top: 0;
}

.companyinfo .companyinfo_title{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 28px;
    color: #3c78bc;
}

.companyinfo .div{
    margin-top: -20px;
    margin-bottom: 20px;
    border-top: 2px solid #3c78bc;
    width: 60px;
}

.company-info {
    display: flex;
}

.company-info .image-container {
    width: 350px;
    height: 500px;
    border: 4px solid rgb(185, 185, 185);
    border-radius: 5px;
    overflow: hidden;
    flex-shrink: 0;
}

.company-info .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.company-info .text-container {
    flex-grow: 1;
    padding: 0 40px;
}

.company-info .text-container h1 {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-top: 0;
}

.company-info .text-container p {
    color: #3c78bc;
    text-align: justify;
}