.modalerror{
    background-color: rgba(0, 0, 0, 0.5);
}

.modalerror .modal__contanair{
    padding: 10px 5px;
    width: 300px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
}

.modalerror .modal__contanair h4{
    margin-top: 5px;
    margin-bottom: 0;
    color: #ff1100;
}

.modalerror #modal__h4{
    color: #383838;
    margin-top: 5px;
    margin-bottom: 6px;
}